var rootElement = document.documentElement;

export function scrollToTop() {
  // Scroll to top logic
  rootElement.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const closeBurger = (e, burgerMenu) => {
  if (burgerMenu.getAttribute('status') === 'open') {
    document.querySelector('[data-element="burger-menu-trigger"]').click();
  } else return;
};
