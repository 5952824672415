import BurgerMenu from './lib/burger-menu';
import * as slider from './lib/slider';
import { scrollToTop, closeBurger } from './lib/handlers';
import { initMap } from './lib/googleMap';

if (document.getElementById('google-maps') !== null) {
  document.getElementById('google-maps').addEventListener(
    'mouseenter',
    () => {
      initMap();
    },
    { once: true },
  );
  // initMap();
}

const scrollToTopBtn = document.getElementById('scrollToTopBtn');

scrollToTopBtn.addEventListener('click', scrollToTop);

const itemMenus = document.querySelectorAll('.burger-menu__panel li a');
const burgerMenu = document.querySelector('[data-element="burger-root"]');

itemMenus.forEach(item => {
  item.addEventListener('click', event => closeBurger(event, burgerMenu));
});
