import { Loader } from "@googlemaps/js-api-loader";
import mapStyle from "../data/mapStyle";

const cabinetSarah = { lat: 43.75816, lng: 4.07751 };
const API_KEY = "AIzaSyC_Bjn6mbm1P-lPNWG_u_5ck2IS7XgkpmQ";

const mapOptions = {
  center: cabinetSarah,
  zoom: 10,
  mapTypeControl: false,
  styles: mapStyle,
};

const loader = new Loader({
  apiKey: API_KEY,
  version: "weekly",
});

// info window
const contentString =
  '<div id="content">' +
  '<p id="firstHeading" class="firstHeading">Cabinet de Sarah Desesquelles</p>' +
  "<p>86 Lot. du Mas de Barre</p>" +
  "<p>34160 BOISSERON</p>";
("</div>");

export function initMap() {
  let map;
  let marker;
  let infoWindow;
  loader
    .load()
    .then(() => {
      map = new google.maps.Map(document.getElementById("google-maps"), mapOptions);
    })
    .then(() => {
      marker = new google.maps.Marker({
        position: cabinetSarah,
        icon: "./images/icons/marker-icon.png",
        map: map,
        title: "Cabinet de Sarah Desesquelles",
      });
    })
    .then(() => {
      infoWindow = new google.maps.InfoWindow({
        content: contentString,
      });
    })
    .then(() => {
      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    })
    .catch(e => {
      console.log("There is a problem", e);
    });
}
